import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Skeleton,
  Button,
  TextField,
} from "@mui/material";
import {
  RadioButtonChecked,
  Star,
  StarOutline,
  TextFormatRounded,
} from "@mui/icons-material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { styled } from "@mui/material/styles";
import BlockHeader from "./BlockHeader";
import { ReactComponent as SportsRefLogo } from "../images/sportsref.svg";
import PlayerCompModal from "./PlayerComparisonModal";

const StyledBioText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.95rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.05rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.25rem",
  },
}));

const StyledLogo = styled(CardMedia)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: "40px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "52px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "64px",
  },
}));

const StyledPlayerName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.400rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.925rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.125rem",
  },
}));

function Bio(props) {
  const [headshotLoaded, setHeadshotLoaded] = React.useState(false);
  const [logoLoaded, setLogoLoaded] = React.useState(false);
  const [showHiddenInfo, setShowHiddenInfo] = React.useState(false);

  const toggleInfo = (event) => {
    if (showHiddenInfo) {
      setShowHiddenInfo(false);
    } else {
      setShowHiddenInfo(true);
    }
  };

  const [modalAnchorEl, setModalAnchorEl] = React.useState(null);
  const openModal = Boolean(modalAnchorEl);

  const handleOpenModal = (event) => {
    setModalAnchorEl(event.currentTarget);
    document.body.style.overflow = "hidden";
  };

  const handleCloseModal = () => {
    setModalAnchorEl(null);
    if (!props.headerMenuOpen) {
      document.body.style.overflow = "auto";
    }
  };

  React.useEffect(() => {
    setModalAnchorEl(null);
    document.body.style.overflow = "auto";
  }, []);

  var transferIndicatorColor;
  var hometownElement;
  var highSchoolElement;
  var playerPosEl = "";
  var playerYearEl = "";
  var playerHeightEl = "";
  var synergyURL = "";

  if (props.status === "In Portal") transferIndicatorColor = "#2FC68A";
  else if (props.status === "Transferred") transferIndicatorColor = "#FE5634";
  else transferIndicatorColor = "#d8d8d8";

  if (props.hometown && props.hometown !== "NaN") {
    hometownElement = (
      <div>
        <span style={{ fontWeight: "bold", fontFamily: "Libre Franklin" }}>
          Hometown:{" "}
        </span>
        &nbsp;
        {props.hometown} <br></br>
      </div>
    );
  }

  if (props.highSchool && props.highSchool !== "NaN") {
    highSchoolElement = (
      <div>
        <span style={{ fontWeight: "bold", fontFamily: "Libre Franklin" }}>
          High School:{" "}
        </span>
        &nbsp;
        {props.highSchool} <br></br>
      </div>
    );
  }

  if (props.pos) {
    playerPosEl = props.pos + " | ";
  }

  if (props.year && props.year !== "NaN") {
    playerYearEl = props.year + " | ";
  }

  if (props.height && props.height !== "NaN") {
    playerHeightEl = props.height;
  }

  if (props.playerId) {
    synergyURL = `https://apps.synergysports.com/basketball/players/${props.playerId}`;
  }

  return (
    <Box>
      <BlockHeader text="Player Bio" />
      <Card
        sx={{
          position: "relative",
          paddingBottom: props.isMobile ? "25px" : "10px",
        }}
      >
        {headshotLoaded ? (
          ""
        ) : (
          <Skeleton
            variant="rounded"
            width={props.isMobile ? "100" : "122"}
            height={props.isMobile ? "" : "180"}
            sx={{
              float: "left",
              marginLeft: props.isMobile ? "0px" : "5px",
              marginRight: props.isMobile ? "0px" : "20px",
              borderRadius: "4px",
            }}
          />
        )}
        <CardMedia
          component="img"
          width={props.isMobile ? "85px" : "122"}
          height={props.isMobile ? "150" : "180"}
          image={props.headshotPath}
          onError={(e) => {
            e.target.src =
              process.env.REACT_APP_STORAGE_BLOB_URL +
              "/" +
              props.league.substring(0, 1) +
              "-headshots/silhouette.png";
          }}
          onLoad={() => {
            setHeadshotLoaded(true);
          }}
          sx={{
            width: "unset",
            maxWidth: props.isMobile ? "90px" : "122px",
            float: "left",
            marginLeft: props.isMobile ? "0px" : "5px",
            marginRight: props.isMobile ? "10px" : "20px",
            border: "1px solid #ececec",
            borderRadius: "4px",
            display: headshotLoaded ? "block" : "none",
          }}
        />
        <CardContent
          sx={{
            padding: "0 !important",
            height: props.isMobile ? "150px" : "180px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignSelf: "end",
              height: "20%",
            }}
          >
            <Button
              sx={{
                display: props.isMobile ? "none" : "",
                background: "white",
                mt: 3,
                width: "100px",
                height: "30px",
                margin: "0px 10px 0px 0px",
                paddingTop: "15px",
                alignItems: "center",
              }}
              variant="outlined"
            >
              <a href={synergyURL} target="_blank">
                <img
                  src="https://auth.synergysportstech.com/img/synergy-logo.png"
                  style={{
                    width: "100px",
                    height: "auto",
                  }}
                ></img>
              </a>
            </Button>
            <Button
              sx={{
                display: props.isMobile ? "none" : "",
                background: "white",
                mt: 3,
                width: "100px",
                height: "30px",
                margin: "0px 10px 0px 0px",
                paddingTop: "11px",
                paddingLeft: "9px !important",
                paddingRight: "15px !important",
                alignItems: "center",
              }}
              variant="outlined"
            >
              <a href={props.srURL} target="_blank">
                <SportsRefLogo style={{ width: "110%", height: "110%" }} />
              </a>
            </Button>
            <Button
              sx={{
                background: "white",
                mt: 3,
                height: "30px",
                margin: "0px",
                padding: "10px",
              }}
              variant="outlined"
              size="large"
              onClick={(event) => handleOpenModal(event)}
              startIcon={<GroupAddIcon />}
            >
              Compare
            </Button>
            {modalAnchorEl == null ? (
              <></>
            ) : (
              <PlayerCompModal
                props={props}
                searchPlayerList={props.searchPlayerList}
                open={openModal}
                handleClose={handleCloseModal}
                anchorEl={modalAnchorEl}
              />
            )}
          </Box>
          {props.name === undefined ? (
            <Skeleton
              width="80%"
              sx={{
                height: "60px",
                display: "flex",
                alignItems: "flex-end",
                paddingBottom: "5px",
                fontWeight: "700",
              }}
            >
              <StyledPlayerName variant="h4">.</StyledPlayerName>
            </Skeleton>
          ) : (
            <StyledPlayerName
              variant="h4"
              component="div"
              sx={{
                height: "40%",
                display: "flex",
                alignItems: "flex-end",
                paddingBottom: props.isMobile ? "2px" : "5px",
                textAlign: "left",
                fontWeight: "700",
                lineHeight: 1,
                textWrap: "balance",
              }}
            >
              <div>
                {props.favoritesListIncluded ? (
                  <Star
                    fontSize={props.isMobile ? "small" : "medium"}
                    sx={{
                      color: "#BF5700",
                      cursor: "pointer",
                      marginBottom: props.isMobile ? "-2px" : "-1px",
                    }}
                    onClick={() => props.removeFavorite(props.playerId)}
                  ></Star>
                ) : (
                  <div className="favorites-button">
                    <StarOutline
                      fontSize={props.isMobile ? "small" : "medium"}
                      sx={{
                        color: "#BF5700",
                        cursor: "pointer",
                        marginBottom: props.isMobile ? "-2px" : "-1px",
                      }}
                      onClick={() => props.addFavorite(props.playerId)}
                    ></StarOutline>
                  </div>
                )}
              </div>
              <div>
                <RadioButtonChecked
                  fontSize={props.isMobile ? "small" : "medium"}
                  sx={{
                    color: transferIndicatorColor,
                    marginRight: "2px",
                    marginBottom: props.isMobile ? "-2px" : "-1px",
                  }}
                />
              </div>
              {props.name}
            </StyledPlayerName>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0px",
              height: "40%",
            }}
          >
            {logoLoaded ? (
              ""
            ) : (
              <Skeleton
                variant="circular"
                width={props.isMobile ? 54 : 64}
                height={props.isMobile ? 54 : 64}
                sx={{
                  float: "left",
                }}
              />
            )}
            <StyledLogo
              component="img"
              height={props.isMobile ? "54" : "64"}
              image={props.logoPath}
              onLoad={() => {
                setLogoLoaded(true);
              }}
              sx={{
                width: "unset",
                float: "left",
                display: logoLoaded ? "block" : "none",
                marginLeft: "3px",
              }}
            />
            <Box
              sx={{
                float: "left",
                marginLeft: props.isMobile ? "8px" : "10px",
              }}
            >
              {props.school === undefined ? (
                <Skeleton width="200px">
                  <StyledBioText variant="h6">.</StyledBioText>
                </Skeleton>
              ) : (
                <StyledBioText
                  variant="h6"
                  sx={{
                    fontWeight: "300",
                    lineHeight: props.isMobile ? "1em" : "1.4em",
                    textWrap: "pretty",
                  }}
                >
                  {props.school}
                </StyledBioText>
              )}
              {props.pos == undefined ||
              props.year === undefined ||
              props.height === undefined ||
              props.weight === undefined ? (
                <Skeleton width="100%">
                  <StyledBioText variant="h6">.</StyledBioText>
                </Skeleton>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "left",
                  }}
                >
                  <StyledBioText variant="h6" sx={{ fontWeight: "300" }}>
                    {playerPosEl +
                      playerYearEl +
                      playerHeightEl +
                      (props.league === "mens" && props.weight
                        ? " | " + props.weight
                        : "")}
                  </StyledBioText>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: props.isMobile ? "5px" : "10px",
              right: props.isMobile ? "5px" : "10px",
            }}
          >
            <Typography
              onClick={toggleInfo}
              variant="h5"
              sx={{
                textTransform: "uppercase",
                fontSize: props.isMobile ? "0.9rem" : "0.9rem",
                textAlign: "right",
                paddingTop: "0px",
                paddingRight: "5px",
                color: "#a9a9a9",
                cursor: "pointer",
              }}
            >
              {showHiddenInfo ? "Show less ▲" : "Show more ▼"}
            </Typography>
          </Box>
        </CardContent>
        {showHiddenInfo ? (
          <Box
            sx={{
              margin: "12px 5px 0px 5px",
              borderTop: "dashed 2px rgba(0,0,0,0.3)",
              lineHeight: "1.4em",
              paddingTop: "5px",
              fontSize: props.isMobile ? "0.85rem" : "1.0rem",
            }}
          >
            <span style={{ fontWeight: "bold", fontFamily: "Libre Franklin" }}>
              Status:
            </span>
            &nbsp;
            <RadioButtonChecked
              fontSize="small"
              sx={{
                color: transferIndicatorColor,
                marginRight: "2px",
                marginBottom: "-4px",
              }}
            />
            {props.status}
            <br></br>
            {hometownElement}
            {highSchoolElement}
            <Button
              sx={{
                display: props.isMobile ? "" : "none",
                background: "white",
                mt: 3,
                width: "100px",
                height: "30px",
                margin: "5px 10px 0px 0px",
                paddingTop: "15px",
                alignItems: "center",
              }}
              variant="outlined"
            >
              <a href={synergyURL} target="_blank">
                <img
                  src="https://auth.synergysportstech.com/img/synergy-logo.png"
                  style={{
                    width: "100px",
                    height: "auto",
                  }}
                ></img>
              </a>
            </Button>
            <Button
              sx={{
                display: props.isMobile ? "" : "none",
                background: "white",
                mt: 3,
                width: "100px",
                height: "30px",
                margin: "5px 10px 0px 0px",
                paddingTop: "11px",
                paddingLeft: "9px !important",
                paddingRight: "15px !important",
                alignItems: "center",
              }}
              variant="outlined"
            >
              <a href={props.srURL} target="_blank">
                <SportsRefLogo style={{ width: "110%", height: "110%" }} />
              </a>
            </Button>
          </Box>
        ) : (
          ""
        )}
      </Card>
    </Box>
  );
}

export default Bio;
