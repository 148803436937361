import * as React from "react";
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  Divider,
  Button,
  Box,
  TransitionComponent,
  Slide,
  Checkbox,
  Typography,
  IconButton,
} from "@mui/material";
import { ArrowRight, Clear, RadioButtonChecked } from "@mui/icons-material";
import FilterMobileSecondary from "./FilterMobileSecondary";
import ConfData from "../data/conferences.json";
import { ClickAwayListener } from "@mui/base";
import StateData from "../data/states.json";
// import { MemoizedFilterChecklist } from './FilterChecklist'

export default function SavedMobile(props) {
  const [filterMenuHeight, setFilterMenuHeight] = React.useState(
    Boolean(props.anchorEl)
      ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
      : 600
  );

  React.useEffect(() => {
    setFilterMenuHeight(
      Boolean(props.anchorEl)
        ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
        : 600
    );
  }, [props.anchorEl]);
  return (
    <div>
      <ClickAwayListener onClickAway={props.handleClose}>
        <div>
          <Box
            sx={{
              //visibility: props.anchorEl == null ? "hidden" : "visibile",
              position: "fixed",
              top: "60px",
              left: "0",
              textAlign: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              width: "100%",
              height: "100%",
              background: "white",
              borderTop: 1,
              borderColor: "rgba(0, 0, 0, 0.20)",
              zIndex: "9996 !important",
              borderRadius: "15px",
              overflow: "auto",
            }}
          >
            <MenuItem
              divider="true"
              disabled="true"
              sx={{
                alignContent: "center",
                textAlign: "center",
                justifyContent: "center",
                color: "black",
                opacity: "1 !important",
              }}
            >
              Saved
            </MenuItem>
            {props.favoritesListItems.map((item) => (
              <MenuItem
                divider="true"
                sx={{ paddingLeft: "12px !important" }}
                key={item.id}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  onClick={() => props.handleClick(item)}
                >
                  <Box display="flex" alignItems="center">
                    <RadioButtonChecked
                      fontSize="small"
                      sx={{
                        color: props.transferColor(item),
                        marginRight: "5px",
                      }}
                    />
                    <Typography
                      width="170px"
                      maxWidth="170px"
                      display="flex"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography
                      width="30px"
                      marginRight="8px"
                      marginLeft="6px"
                      alignItems="center"
                    >
                      {item.pos}
                    </Typography>
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_BLOB_URL +
                        "/college-logos/" +
                        item.teamname +
                        ".jpg"
                      }
                      style={{
                        height: "20px",
                        marginRight: "8px",
                      }}
                    ></img>
                  </Box>
                </Box>
                <IconButton onClick={() => props.removeFavorite(item.playerid)}>
                  <Clear sx={{ color: "#D32F2F" }} fontSize="small"></Clear>
                </IconButton>
              </MenuItem>
            ))}
            <MenuItem></MenuItem>
            <MenuItem></MenuItem>
            <MenuItem></MenuItem>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              position: "fixed",
              bottom: "0",
              left: "0",
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              width: "100%",
              background: "white",
              borderTop: 1,
              zIndex: "9999",
              borderColor: "rgba(0, 0, 0, 0.20)",
            }}
          >
            <Button
              sx={{ background: "white", mr: "12px" }}
              onClick={props.handleClose}
              variant="outlined"
              size="small"
            >
              CLOSE
            </Button>
          </Box>
        </div>
      </ClickAwayListener>
    </div>
  );
}
