import * as React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledRoleName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "0.8rem !important",
    width: "20%",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "0.9rem !important",
    width: "20%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
    width: "27%",
  },
}));

const StyledPercentText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "0.8rem !important",
    width: "10%",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "0.9rem !important",
    width: "10%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
    width: "12%",
  },
}));

function ProgressBar(props) {
  const [progress, setProgress] = React.useState(props.previousScore);

  const worstColor = "rgb(254, 86, 52)";
  const badColor = "#ffb92c";
  const avgColor = "#ffe400";
  const goodColor = "#a7e38f";
  const bestColor = "rgb(47, 198, 138)";

  const svgWidth = 500;
  const svgHeight = 21;
  const barWidth = 470;
  const barHeight = 21;
  const barStartX = svgWidth / 2 - barWidth / 2;

  let finalValue = parseFloat(props.score);
  if (isNaN(finalValue)) {
    finalValue = 50;
  }

  let barDivergeWidth = Math.abs(finalValue - 50);
  let barChangeFromPrevious = 0;

  React.useLayoutEffect(() => {
    const startingValue = parseFloat(props.previousScore);
    barChangeFromPrevious = Math.abs(finalValue - startingValue);
    const transitionDuration = (2 * barChangeFromPrevious) / 50;

    let currentStep = 0;
    let currentValue = startingValue;
    const stepDuration = transitionDuration / 100;
    const totalSteps = transitionDuration / stepDuration;
    const stepSize = Math.abs(finalValue - currentValue) / totalSteps;

    const interval = setInterval(
      () =>
        setProgress((progress) => {
          currentStep++;
          if (currentStep >= totalSteps) {
            clearInterval(interval);
            return finalValue;
          }
          const stepPct = currentStep * stepSize;
          let pct = currentValue;
          if (finalValue >= startingValue) {
            pct = currentValue + stepPct;
          } else {
            pct = currentValue - stepPct;
          }
          return parseInt(Math.round(pct * 100) / 100);
        }),
      stepDuration * 1000
    );
    return () => clearInterval(interval);
  }, [finalValue]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <StyledRoleName
        sx={{
          margin: "0",
          fontWeight: "500",
          display: "inline-block",
          alignItems: "center",
          justifyContent: "right",
          textAlign: "right",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textWrap: "nowrap",
          whiteSpace: "nowrap",
          fontWeight: props.roleName == "Impact Score" ? "bold" : "normal",
        }}
      >
        {props.roleName}
      </StyledRoleName>
      <Box
        sx={{
          width: { xs: "70%", sm: "70%", md: "61%", lg: "61%", xl: "61%" },
          display: "inline-flex",
          position: "relative",
          height: "10px",
          alignItems: "center",
        }}
      >
        {props.showBarLabel ? (
          <Box
            sx={{
              position: "absolute",
              top: "-1.6em",
              textAlign: "center",
              width: "100%",
              fontSize: "0.7em",
              fontWeight: "500",
            }}
          >
            AVG
          </Box>
        ) : (
          ""
        )}
        <svg
          width="100%"
          height="100%"
          preserveAspectRatio="none"
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        >
          <defs>
            <linearGradient
              xmlns="http://www.w3.org/2000/svg"
              id="gradient"
              x1={barStartX}
              y1="0"
              x2={barStartX + barWidth}
              y2="0"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="10%" stopColor={worstColor} />
              <stop offset="30%" stopColor={badColor} />
              <stop offset="50%" stopColor={avgColor} />
              <stop offset="70%" stopColor={goodColor} />
              <stop offset="90%" stopColor={bestColor} />
            </linearGradient>
          </defs>
          <rect
            x={barStartX}
            y={0}
            width={barWidth}
            height={barHeight}
            fill={"#d9d9d9"}
            rx="1.8%"
          />
          <rect
            x={
              progress >= 50
                ? barWidth / 2 + barStartX
                : barWidth / 2 +
                  barStartX -
                  (barWidth *
                    ((50 - progress) / barDivergeWidth) *
                    barDivergeWidth) /
                    100
            }
            y={0}
            width={
              progress === 50
                ? 0
                : progress > 50
                ? barDivergeWidth !== 0
                  ? (barWidth *
                      ((progress - 50) / barDivergeWidth) *
                      barDivergeWidth) /
                    100
                  : 0
                : barDivergeWidth !== 0
                ? (barWidth *
                    ((50 - progress) / barDivergeWidth) *
                    barDivergeWidth) /
                  100
                : 0
            }
            height={barHeight}
            rx="1.8%"
            fill={"url(#gradient)"}
          />
          <rect
            x={barWidth / 2 + barStartX - 1}
            y={0}
            width="2"
            height={barHeight}
            fill={"#000"}
          />
        </svg>
      </Box>
      <StyledPercentText
        variant="body1"
        sx={{
          margin: "0",
          fontWeight: "500",
          display: "inline-flex",
          alignItems: "center",
          width: "12%",
          justifyContent: "left",
        }}
      >
        {isNaN(progress) ? 50 : progress}%
      </StyledPercentText>
    </Box>
  );
}

export default ProgressBar;
