import * as React from "react";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Select from "@mui/material/Select";

/** display a singular role filter in a list */
export default function RoleFilter(props) {
  const [role, setRole] = React.useState(props.filter.role);
  const [operator, setOperator] = React.useState(props.filter.operator);
  const [threshold, setThreshold] = React.useState(props.filter.threshold);

  // update roleFilters in TransferIQ.jsx each time a field is changed

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
    let filter = {
      key: props.filter.key,
      role: role,
      operator: event.target.value,
      threshold: threshold,
    };
    if (role && threshold) props.updateRoleFilters(props.filter.key, 1, filter);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    let filter = {
      key: props.filter.key,
      role: event.target.value,
      operator: operator,
      threshold: threshold,
    };
    if (threshold) props.updateRoleFilters(props.filter.key, 1, filter);
  };

  const handleThresholdChange = (event) => {
    setThreshold(event.target.value);
    let filter = {
      key: props.filter.key,
      role: role,
      operator: operator,
      threshold: event.target.value,
    };
    if (role) props.updateRoleFilters(props.filter.key, 1, filter);
  };

  return (
    <Grid container p={1}>
      <Grid item xs={5} p={1}>
        <InputLabel
          id={"role-select-label-" + props.filter.key}
          sx={{ fontSize: "12px" }}
        >
          Role
        </InputLabel>
        <Select
          variant="standard"
          labelId={"role-select-label-" + props.filter.key}
          id={"role-simple-select-" + props.filter.key}
          value={role}
          label="Role"
          defaultValue={props.filter.role}
          onChange={handleRoleChange}
          MenuProps={{ style: { zIndex: 9998 }, disablePortal: true }}
          sx={{ width: "100%" }}
        >
          {props.filter.role != "" ? (
            <MenuItem key={props.filter.key} value={props.filter.role}>
              {props.selectedFilterName}
            </MenuItem>
          ) : (
            ""
          )}
          {props.availableRoles.map((role, index) => {
            return (
              <MenuItem key={index} value={role}>
                {props.availableRolesFormatted[index]}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={3} py={1}>
        <FormControl fullWidth variant="standard">
          <InputLabel id={"operator-select-label-" + props.filter.key}>
            Operator
          </InputLabel>
          <Select
            labelId={"operator-select-label-" + props.filter.key}
            id={"operator-simple-select-" + props.filter.key}
            value={operator}
            label="Operator"
            onChange={handleOperatorChange}
            MenuProps={{ style: { zIndex: 2100 }, disablePortal: true }}
          >
            <MenuItem value={"greater_than"}>&gt;</MenuItem>
            <MenuItem value={"less_than"}>&lt;</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} p={1}>
        <TextField
          variant="standard"
          labelId={"thresh-select-label-" + props.filter.key}
          id={"thresh-simple-select-" + props.filter.key}
          label="Threshold"
          value={threshold}
          onChange={handleThresholdChange}
          type="text"
        ></TextField>
      </Grid>
    </Grid>
  );
}
