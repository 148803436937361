import * as React from "react";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import { ArrowDownward, Clear } from "@mui/icons-material";
import { ClickAwayListener } from "@mui/base";
// import { MemoizedFilterChecklist } from './FilterChecklist'

export default function SortMobile(props) {
  const [sortMenuHeight, setSortMenuHeight] = React.useState(
    Boolean(props.anchorEl)
      ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
      : 600
  );

  React.useEffect(() => {
    setSortMenuHeight(
      Boolean(props.anchorEl)
        ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
        : 600
    );
  }, [props.anchorEl]);

  const handleButtonClick = (fieldName) => {
    // Call the updateSort function
    if (fieldName === undefined) {
      props.updateSort([]);
    } else if (fieldName.field != undefined) {
      props.updateSort([{ field: fieldName.field, sort: "desc" }]);
    } else if (fieldName != "") {
      props.updateSort([{ field: fieldName, sort: "desc" }]);
    } else {
      props.updateSort([]);
    }

    // Call the handleSort function
    props.handleSort();
    //props.anchorEl = null;
    //setSortMenuHeight(0);
  };

  return (
    <div>
      <ClickAwayListener
        onClickAway={() => handleButtonClick(props.sortModel[0])}
      >
        <div>
          <Box
            sx={{
              //visibility: props.anchorEl == null ? "hidden" : "visibile",
              position: "fixed",
              top: "60px",
              left: "0",
              textAlign: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              width: "100%",
              height: "100%",
              background: "white",
              borderTop: 1,
              borderColor: "rgba(0, 0, 0, 0.20)",
              zIndex: "9996 !important",
              borderRadius: "15px",
            }}
          >
            <MenuItem
              divider="true"
              disabled="true"
              sx={{
                alignContent: "center",
                textAlign: "center",
                justifyContent: "center",
                color: "black",
                opacity: "1 !important",
              }}
            >
              Sort
            </MenuItem>
            <MenuItem
              divider="true"
              id="position-filter"
              /*
            onClick={() => {
              props.updateSort([{ field: "scorer", sort: "desc" }]);
              props.handleSort();
            }}
            */
              sx={
                props.sortModel.length > 0
                  ? props.sortModel[0].field === "scorer" &&
                    props.sortModel[0].sort === "desc"
                    ? { color: "rgb(47, 198, 138)" }
                    : ""
                  : ""
              }
              onClick={() => handleButtonClick("scorer")}
            >
              <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
              Best Scorers
            </MenuItem>
            <MenuItem
              divider="true"
              id="position-filter"
              onClick={() => handleButtonClick("shooter")}
              sx={
                props.sortModel.length > 0
                  ? props.sortModel[0].field === "shooter" &&
                    props.sortModel[0].sort === "desc"
                    ? { color: "rgb(47, 198, 138)" }
                    : ""
                  : ""
              }
            >
              <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
              Best Shooters
            </MenuItem>
            <MenuItem
              divider="true"
              id="position-filter"
              onClick={() => handleButtonClick("finisher")}
              sx={
                props.sortModel.length > 0
                  ? props.sortModel[0].field === "finisher" &&
                    props.sortModel[0].sort === "desc"
                    ? { color: "rgb(47, 198, 138)" }
                    : ""
                  : ""
              }
            >
              <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
              Best Finishers
            </MenuItem>
            <MenuItem
              divider="true"
              id="position-filter"
              onClick={() => handleButtonClick("playmaker")}
              sx={
                props.sortModel.length > 0
                  ? props.sortModel[0].field === "playmaker" &&
                    props.sortModel[0].sort === "desc"
                    ? { color: "rgb(47, 198, 138)" }
                    : ""
                  : ""
              }
            >
              <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
              Best Playmakers
            </MenuItem>
            <MenuItem
              divider="true"
              id="position-filter"
              onClick={() => handleButtonClick("rebounder")}
              sx={
                props.sortModel.length > 0
                  ? props.sortModel[0].field === "rebounder" &&
                    props.sortModel[0].sort === "desc"
                    ? { color: "rgb(47, 198, 138)" }
                    : ""
                  : ""
              }
            >
              <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
              Best Rebounders
            </MenuItem>
            <MenuItem
              divider="true"
              id="position-filter"
              onClick={() => handleButtonClick("defender")}
              sx={
                props.sortModel.length > 0
                  ? props.sortModel[0].field === "defender" &&
                    props.sortModel[0].sort === "desc"
                    ? { color: "rgb(47, 198, 138)" }
                    : ""
                  : ""
              }
            >
              <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
              Best Defenders
            </MenuItem>
            <MenuItem
              divider="true"
              id="position-filter"
              onClick={() => handleButtonClick("rimprotector")}
              sx={
                props.sortModel.length > 0
                  ? props.sortModel[0].field === "rimprotector" &&
                    props.sortModel[0].sort === "desc"
                    ? { color: "rgb(47, 198, 138)" }
                    : ""
                  : ""
              }
            >
              <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
              Best Rim Protectors
            </MenuItem>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              position: "fixed",
              bottom: "0",
              left: "0",
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              width: "100%",
              background: "white",
              borderTop: 1,
              borderColor: "rgba(0, 0, 0, 0.20)",
              zIndex: "9999 !important",
            }}
          >
            <Button
              sx={{ background: "white" }}
              variant="outlined"
              size="small"
              startIcon={<Clear />}
              onClick={() => handleButtonClick("")}
            >
              Unsort
            </Button>
          </Box>
        </div>
      </ClickAwayListener>
    </div>
  );
}
