import { GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { RadioButtonChecked } from "@mui/icons-material";

function PlayerTableFooter(props) {
  return (
    <GridFooterContainer>
      <Box
        sx={{
          display: props.isMobile ? "inline-block" : "flex",
          //display: "inline-block",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            fontSize: "0.9em",
            paddingLeft: "10px",
            paddingBottom: props.isMobile ? "5px" : "",
          }}
        >
          <RadioButtonChecked
            fontSize="small"
            sx={{
              color: "#2FC68A",
              marginRight: "5px",
              marginBottom: "-5px",
              marginLeft: props.isMobile ? "0px" : "5px",
            }}
          />
          In Portal
        </Box>
        <Box pl={3} sx={{ fontSize: "0.9em", paddingLeft: "10px" }}>
          <RadioButtonChecked
            fontSize="small"
            sx={{
              color: "#FE5634",
              // marginRight: props.isMobile ? "4px" : "0px",
              marginRight: "4px",
              //marginLeft: props.isMobile ? "0px" : "5px",
              marginBottom: "-5px",
            }}
          />
          Transferred
        </Box>
      </Box>
      <GridFooter sx={{ border: "none" }} />
    </GridFooterContainer>
  );
}

export default PlayerTableFooter;
