import * as React from "react";
import { Box } from "@mui/material";
import { useD3 } from "../hooks/useD3";
import MakeMarker from "../images/make.png";
import MissMarker from "../images/miss.png";

function ShotChart(props) {
  const [regionState, setRegionState] = React.useState({
    paintHovered: false,
    midrangeHovered: false,
    threePtHovered: false,
  });

  const data = props.data;
  const courtWidth = 469;
  const courtHeight = 444;
  const markerWidth = 26;
  const markerHeight = 26;
  const xOffset = courtWidth / 2 - markerWidth / 2;
  const yOffset = 0.113 * courtHeight - markerHeight / 2;
  const xScaleFactor = courtWidth / 500; // data court width = 500
  const yScaleFactor = courtHeight / 470; // data court height = 470

  const paintAvg = props.shotAverages.paint;
  const midrangeAvg = props.shotAverages.mid;
  const threePtAvg = props.shotAverages.three_pt;

  const worstColor = "rgb(254, 86, 52)";
  const badColor = "#ffb92c";
  const avgColor = "#ffe400";
  const goodColor = "#a7e38f";
  const bestColor = "rgb(47, 198, 138)";

  let paintColor = avgColor;
  let midColor = avgColor;
  let threeColor = avgColor;

  const getStats = (chartData) => {
    let paintMakes = 0,
      paintMisses = 0,
      paintAtt = 0;
    let midrangeMakes = 0,
      midrangeMisses = 0,
      midrangeAtt = 0;
    let threePtMakes = 0,
      threePtMisses = 0,
      threePtAtt = 0;

    for (let i = 0; i < chartData.length; i++) {
      // Paint
      // 70 inches = half of paint = 58.33 data points
      // 166 inches from origin to top of paint = 138.33
      if (Math.abs(chartData[i].shotx) <= 58 && chartData[i].shoty < 138) {
        paintAtt++;
        if (chartData[i].outcome === "Make") {
          paintMakes++;
        } else {
          paintMisses++;
        }
      }
      // 3 Pt
      else if (chartData[i].is3pt === "Yes") {
        threePtAtt++;
        if (chartData[i].outcome === "Make") {
          threePtMakes++;
        } else {
          threePtMisses++;
        }
      }
      // Midrange
      else {
        midrangeAtt++;
        if (chartData[i].outcome === "Make") {
          midrangeMakes++;
        } else {
          midrangeMisses++;
        }
      }
    }

    // FG pct
    let paintFGPct =
      paintAtt === 0
        ? (0.0).toFixed(1)
        : ((paintMakes / paintAtt) * 100).toFixed(1);
    let midrangeFGPct =
      midrangeAtt === 0
        ? (0.0).toFixed(1)
        : ((midrangeMakes / midrangeAtt) * 100).toFixed(1);
    let threePtFGPct =
      threePtAtt === 0
        ? (0.0).toFixed(1)
        : ((threePtMakes / threePtAtt) * 100).toFixed(1);

    // Region colors
    let paintDev = paintFGPct - paintAvg * 100;
    if (paintDev > 5) {
      paintColor = bestColor;
    } else if (paintDev < -5) {
      paintColor = worstColor;
    }
    let midrangeDev = midrangeFGPct - midrangeAvg * 100;
    if (midrangeDev > 5) {
      midColor = bestColor;
    } else if (midrangeDev < -5) {
      midColor = worstColor;
    }
    let threePtDev = threePtFGPct - threePtAvg * 100;
    if (threePtDev > 5) {
      threeColor = bestColor;
    } else if (threePtDev < -5) {
      threeColor = worstColor;
    }

    const stats = {
      paint: {
        FGPct: paintFGPct,
        FG: paintMakes + "-" + paintAtt,
        color: paintColor,
      },
      midrange: {
        FGPct: midrangeFGPct,
        FG: midrangeMakes + "-" + midrangeAtt,
        color: midColor,
      },
      threePt: {
        FGPct: threePtFGPct,
        FG: threePtMakes + "-" + threePtAtt,
        color: threeColor,
      },
    };
    return stats;
  };

  const hoverPaint = () => {
    setRegionState(() => {
      return {
        paintHovered: true,
        midrangeHovered: false,
        threePtHovered: false,
      };
    });
  };

  const hoverMidrange = () => {
    setRegionState(() => {
      return {
        paintHovered: false,
        midrangeHovered: true,
        threePtHovered: false,
      };
    });
  };

  const hover3Pt = () => {
    setRegionState(() => {
      return {
        paintHovered: false,
        midrangeHovered: false,
        threePtHovered: true,
      };
    });
  };

  const handleMouseLeave = () => {
    setRegionState(() => {
      return {
        paintHovered: false,
        midrangeHovered: false,
        threePtHovered: false,
      };
    });
  };

  const ref = useD3(
    (svg) => {
      svg.select("#makes").selectAll("*").remove();
      svg.select("#misses").selectAll("*").remove();

      if (props.timeframe === "Single Game") {
        for (let i = 0; i < data.length; i++) {
          if (data[i].outcome === "Make") {
            svg
              .select("#makes")
              .append("image")
              .attr("href", MakeMarker)
              .attr("width", markerWidth)
              .attr("height", markerHeight)
              .attr("x", data[i].shotx * xScaleFactor + xOffset)
              .attr("y", data[i].shoty * yScaleFactor + yOffset)
              .attr(
                "style",
                "filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5))"
              );
          } else if (data[i].outcome === "Miss") {
            svg
              .select("#misses")
              .append("image")
              .attr("href", MissMarker)
              .attr("width", markerWidth)
              .attr("height", markerHeight)
              .attr("x", data[i].shotx * xScaleFactor + xOffset)
              .attr("y", data[i].shoty * yScaleFactor + yOffset);
          }
        }
      }
    },
    [data, props.timeframe]
  );

  const stats = getStats(data);

  return (
    <Box>
      <Box id="ShotChart">
        <svg
          style={{
            height: "100%",
            width: "100%",
          }}
          viewBox={`0 -4 451 424`}
        >
          <defs>
            <pattern
              id="courtWoodPattern"
              patternUnits="userSpaceOnUse"
              width="128"
              height="128"
            >
              <image
                href={
                  process.env.REACT_APP_STORAGE_BLOB_URL +
                  "/images/wood_light_2.png"
                }
                x="0"
                y="0"
                width="128"
                height="128"
              />
            </pattern>
          </defs>
          <rect
            style={{ height: "100%", width: "100%" }}
            fill="url(#courtWoodPattern)"
          />
        </svg>
        <svg
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0",
            left: "0",
          }}
          viewBox={`0 0 451 424`}
        >
          <image style={{ height: "100%", width: "100%" }} 
            href={process.env.REACT_APP_STORAGE_BLOB_URL + "/images/court.svg"} />
        </svg>
        <svg
          ref={ref}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            overflow: "visible",
          }}
          viewBox={`0 0 454 426`}
          onMouseLeave={handleMouseLeave}
        >
          <g
            id="misses"
            style={{ visibility: props.showMisses ? "visible" : "hidden" }}
          ></g>
          <g
            id="makes"
            style={{ visibility: props.showMakes ? "visible" : "hidden" }}
          ></g>
          <g
            id="regions"
            style={{
              visibility: props.timeframe === "Season" ? "visible" : "hidden",
            }}
          >
            <g id="3pt" transform="translate(4,4)" onMouseEnter={hover3Pt}>
              <path
                stroke="white"
                strokeWidth="2"
                fillOpacity={
                  regionState.midrangeHovered || regionState.paintHovered
                    ? "0.2"
                    : "0.6"
                }
                fill={stats.threePt.color}
                d="M.5,.5V418.46H446.29V.5h-29.74l-.06,88.53c-4.96,16.53-24.12,72.99-82.11,111.47-45.88,30.45-91.59,33.4-110.47,33.55-17.23,.02-69.48-2.13-120-39.34C52.92,157.16,35.46,107.3,30.46,90.88L30.24,.5H.5Z"
              />
              <g id="3ptLabel" transform="translate(180,255)">
                <rect
                  fill="white"
                  style={
                    regionState.threePtHovered
                      ? { filter: "drop-shadow(2px 2px 2px rgb(0 0 0 / 0.4))" }
                      : {}
                  }
                  fillOpacity={
                    regionState.midrangeHovered || regionState.paintHovered
                      ? "0.5"
                      : "1"
                  }
                  width="85"
                  height="50"
                  rx="2"
                  ry="2"
                  transform="translate(0,0)"
                />
                <rect
                  stroke={stats.threePt.color}
                  strokeWidth="2"
                  fillOpacity="0"
                  width="77"
                  height="42"
                  transform="translate(4,4)"
                />
                <text
                  className={props.isMobile ? "statMobile" : "stat"}
                  textAnchor="end"
                  x="51"
                  y="22"
                >
                  {stats.threePt.FGPct}
                </text>
                <text
                  className={props.isMobile ? "statMobile" : "stat"}
                  textAnchor="end"
                  x="51"
                  y="40"
                >
                  {stats.threePt.FG}
                </text>
                <text className="statLabel" x="55" y="25">
                  FG%
                </text>
                <text className="statLabel" x="55" y="45">
                  FG
                </text>
              </g>
            </g>
            <g
              id="midrange"
              transform="translate(34,4)"
              onMouseEnter={hoverMidrange}
            >
              <path
                stroke="white"
                strokeWidth="2"
                fillOpacity={
                  regionState.paintHovered || regionState.threePtHovered
                    ? "0.2"
                    : "0.6"
                }
                fill={stats.midrange.color}
                d="M317.86,.5h68.51l.38,88.49c-5.37,16.58-28.57,83.46-98,120.53-38.1,20.34-74.54,23.97-94.81,24-19.29,.48-59.2-3.2-101.28-27.15C27.31,169.17,5.19,104.1,.5,88.67V.5H141.41l-.06,167.74h104.04l.13-167.74h72.34Z"
              />
              <g id="MidrangeLabel" transform="translate(150,174)">
                <rect
                  fill="white"
                  style={
                    regionState.midrangeHovered
                      ? { filter: "drop-shadow(2px 2px 2px rgb(0 0 0 / 0.4))" }
                      : {}
                  }
                  fillOpacity={
                    regionState.paintHovered || regionState.threePtHovered
                      ? "0.5"
                      : "1"
                  }
                  width="85"
                  height="50"
                  rx="2"
                  ry="2"
                  transform="translate(0,0)"
                />
                <rect
                  stroke={stats.midrange.color}
                  strokeWidth="2"
                  fillOpacity="0"
                  width="77"
                  height="42"
                  transform="translate(4,4)"
                />
                <text
                  className={props.isMobile ? "statMobile" : "stat"}
                  textAnchor="end"
                  x="51"
                  y="22"
                >
                  {stats.midrange.FGPct}
                </text>
                <text
                  className={props.isMobile ? "statMobile" : "stat"}
                  textAnchor="end"
                  x="51"
                  y="40"
                >
                  {stats.midrange.FG}
                </text>
                <text className="statLabel" x="55" y="25">
                  FG%
                </text>
                <text className="statLabel" x="55" y="45">
                  FG
                </text>
              </g>
            </g>
            <g
              id="paint"
              transform="translate(176,4)"
              onMouseEnter={hoverPaint}
            >
              <rect
                stroke="white"
                strokeWidth="2"
                fillOpacity={
                  regionState.midrangeHovered || regionState.threePtHovered
                    ? "0.2"
                    : "0.6"
                }
                fill={stats.paint.color}
                x=".5"
                y=".5"
                width="102.04"
                height="166.98"
              />
              <g id="PaintLabel" transform="translate(8,95)">
                <rect
                  fill="white"
                  style={
                    regionState.paintHovered
                      ? { filter: "drop-shadow(2px 2px 2px rgb(0 0 0 / 0.4))" }
                      : {}
                  }
                  fillOpacity={
                    regionState.midrangeHovered || regionState.threePtHovered
                      ? "0.5"
                      : "1"
                  }
                  width="85"
                  height="50"
                  rx="2"
                  ry="2"
                  transform="translate(0,0)"
                />
                <rect
                  stroke={stats.paint.color}
                  strokeWidth="2"
                  fillOpacity="0"
                  width="77"
                  height="42"
                  transform="translate(4,4)"
                />
                <text
                  className={props.isMobile ? "statMobile" : "stat"}
                  textAnchor="end"
                  x="51"
                  y="22"
                >
                  {stats.paint.FGPct}
                </text>
                <text
                  className={props.isMobile ? "statMobile" : "stat"}
                  textAnchor="end"
                  x="51"
                  y="40"
                >
                  {stats.paint.FG}
                </text>
                <text className="statLabel" x="55" y="25">
                  FG%
                </text>
                <text className="statLabel" x="55" y="45">
                  FG
                </text>
              </g>
            </g>
          </g>
        </svg>
      </Box>
    </Box>
  );
}

export default ShotChart;
