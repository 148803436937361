import { IconButton, Box, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import React, { useState } from "react";

export default function HamburgerMenu(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    if (open) {
      props.setHeaderMenuOpen(true);
      document.body.style.overflow = "hidden";
    } else {
      handleMenuClose();
      props.setHeaderMenuOpen(false);
      document.body.style.overflow = "auto";
    }
  }, [open]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLeagueSelect = (event, league) => {
    props.updateSelectedLeague(league);
    props.updateSelectedPlayer(null, null, null);
    props.clearSearchBar();
    navigate("/");
    // if (league == "womens") {
    //   navigate("/womens");
    // } else {
    //   navigate("/transferiq");
    // }
  };

  return (
    <Box>
      <IconButton
        onClick={handleMenuClick}
        sx={{
          background: "#c97026",
          borderRadius: "4px",
          border: "1px solid rgba(255, 255, 255, 0.8)",
          mr: props.isMobile ? "10px" : "22px",
        }}
      >
        <MenuIcon sx={{ color: "#fff" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="main-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        <MenuItem
          className={props.league === "mens" ? "selectedLeague" : ""}
          onClick={(event) => handleLeagueSelect(event, "mens")}
        >
          <SportsBasketballIcon color="primary" sx={{ mr: "5px" }} /> Men's
          Basketball
        </MenuItem>
        <MenuItem
          className={props.league === "womens" ? "selectedLeague" : ""}
          onClick={(event) => handleLeagueSelect(event, "womens")}
        >
          <SportsBasketballIcon color="primary" sx={{ mr: "5px" }} /> Women's
          Basketball
        </MenuItem>
      </Menu>
    </Box>
  );
}
