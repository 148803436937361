import * as React from "react";
import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";

export function FilterChecklist(props) {
  return (
    <FormGroup sx={{ pointerEvents: "auto", marginLeft: "10px" }}>
      {props.listData.map((item, index) => {
        const isSelected = props.filter.includes(item);
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={isSelected}
                onChange={() => {
                  props.handleCheckItem(item);
                }}
              />
            }
            label={
              props.useImage ? (
                <React.Fragment>
                  <img
                    src={
                      process.env.REACT_APP_STORAGE_BLOB_URL +
                      "/college-logos/" +
                      item +
                      ".jpg"
                    }
                    style={{
                      height: "22px",
                      marginRight: "10px",
                      marginBottom: "-5px",
                    }}
                  />
                  {item}
                </React.Fragment>
              ) : (
                item
              )
            }
          />
        );
      })}
    </FormGroup>
  );
}
export const MemoizedFilterChecklist = React.memo(FilterChecklist);
