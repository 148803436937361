import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState, useRef, useEffect } from "react";
import { Paper, Menu, MenuItem, ListItemText } from "@mui/material";
import Bevo from "../images/Athletics_Bevo_Silhouette.svg";
import HamburgerMenu from "./HamburgerMenu";
import { FixedSizeList } from "react-window";
import { AccountCircle, Logout, HelpOutline } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import Fuse from "fuse.js";
import UserGuide from "../TransferIQ User Guide 2023-24.pdf";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },

  //TODO: remove, testing breakpoints
  // [theme.breakpoints.between('xs', 'sm')]: {
  //   backgroundColor: "red",
  // },
  // [theme.breakpoints.between('sm', 'md')]: {
  //   backgroundColor: "orange",
  // },
  // [theme.breakpoints.between('md', 'lg')]: {
  //   backgroundColor: "yellow",
  // },
  // [theme.breakpoints.between('lg', 'xl')]: {
  //   backgroundColor: "green",
  // },
  // [theme.breakpoints.up('xl')]: {
  //   backgroundColor: "blue",
  // },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
    },
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "10ch",
      "&:focus": {
        width: "13ch",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "13ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Header(props) {
  const boxRef = useRef(null);

  const [query, setQuery] = useState("");
  const [playerList, setPlayerList] = useState([]);

  const options = {
    includeScore: true,
    findAllMatches: false,
    keys: ["name"],
    threshold: 0.4,
  };

  const fuse = new Fuse(playerList, options);

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState(null);

  const accountOpen = Boolean(accountMenuAnchorEl);
  const handleAccountClick = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
    setSearchIconEl(null);
    if (accountOpen) {
      handleAccountClose();
    }
  };

  const handleAccountClose = () => {
    setAccountMenuAnchorEl(null);
    setSearchIconEl(null);
  };

  React.useEffect(() => {
    if (accountOpen) {
      props.setHeaderMenuOpen(true);
      document.body.style.overflow = "hidden";
    } else {
      handleAccountClose();
      props.setHeaderMenuOpen(false);
      document.body.style.overflow = "auto";
    }
  }, [accountOpen]);

  const [searchIconEl, setSearchIconEl] = React.useState(null);

  const searchOpen = Boolean(searchIconEl);
  const searchInputRef = useRef(null);

  const handleSearchBarClick = (event) => {
    setSearchIconEl(!searchOpen);
    setAccountMenuAnchorEl(null);
    clearSearchBar();
    if (searchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    } else {
    }
    //console.log(searchOpen);
  };

  React.useEffect(() => {
    // console.log("inside use effect");
    if (searchOpen) {
      props.setHeaderMenuOpen(true);
      document.body.style.overflow = "hidden";
    } else {
      props.setHeaderMenuOpen(false);
      document.body.style.overflow = "auto";
    }
    //console.log(searchOpen);
  }, [searchOpen]);

  const clearSearchBar = () => {
    setQuery("");
  };

  // scroll to top when search icon is clicked on mobile
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    //console.log("running");
    document.body.style.overflow = "hidden";
  };

  React.useEffect(() => {
    setPlayerList(props.playerList);
  }, [props.playerList]);

  let filteredList = query === "" ? "" : fuse.search(query.toLowerCase());

  const Row = ({ index, style }) => (
    <a
      href={`/player/${props.league}/${filteredList[index].item.season}/${filteredList[index].item.playerid}`}
      target="_blank"
      style={{ textDecoration: "none", color: "inherit" }}
      onClick={(event) => {
        // only update selected player if different than current player to prevent clearing portal status
        if (!event.ctrlKey && !event.metaKey && event.button === 0) {
          event.preventDefault();
          if (
            filteredList[index].item.playerid !== props.playerId ||
            filteredList[index].item.season !== props.season
          ) {
            props.updateSelectedPlayer(
              filteredList[index].item.playerid,
              filteredList[index].item.season,
              filteredList[index].item.history,
              filteredList[index].item.portalStatus // currently undefined
            );
            props.updateFirstRender(true);
            //navigate("/player/" + filteredList[index].item.playerid);
            props.setLoadingFromSearch(true);
            navigate(
              "/player/" +
              props.league +
              "/" +
              filteredList[index].item.season +
              "/" +
              filteredList[index].item.playerid
            );
          }
          setQuery("");
          handleSearchBarClick();
        }
      }}
    >
      <MenuItem style={style} key={filteredList[index].item.playerid}>
        <ListItemText>{filteredList[index].item.name}</ListItemText>
      </MenuItem>
    </a>
  );

  const navigate = useNavigate();
  const handleTransferIQClick = () => {
    props.updateSelectedPlayer(null, null, null);
    setQuery("");
    navigate("");
  };

  const onKeyDown = (e) => {
    e.stopPropagation();
  };

  return (
    <Box sx={{ flexGrow: 1 }} ref={boxRef}>
      <AppBar
        position="relative"
        sx={{
          backgroundColor: "#BF5700",
          zIndex: 1,
        }}
      >
        <Container
          style={{
            paddingLeft: props.isMobile ? "18px" : "",
            paddingRight: props.isMobile ? "4px" : "",
          }}
        >
          <Toolbar
            style={{
              paddingLeft: props.isMobile ? "0px" : "",
              paddingRight: props.isMobile ? "0px" : "",
            }}
          >
            <HamburgerMenu
              league={props.league}
              updateSelectedLeague={props.updateSelectedLeague}
              updateSelectedPlayer={props.updateSelectedPlayer}
              clearSearchBar={clearSearchBar}
              isMobile={props.isMobile}
              setHeaderMenuOpen={props.setHeaderMenuOpen}
            />
            <img
              src={Bevo}
              style={{
                height: props.isMobile ? "30px" : "42px",
                marginRight: props.isMobile ? "5px" : "10px",
                cursor: "pointer",
              }}
              onClick={(event) => {
                handleTransferIQClick();
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h4"
                noWrap
                component="div"
                sx={{
                  flexGrow: 1,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  cursor: "pointer",
                  lineHeight: "1em",
                  marginTop: "6px",
                  marginBottom: "1px",
                  fontSize: props.isMobile ? "24px" : "36px",
                }}
                onClick={(event) => {
                  handleTransferIQClick();
                }}
              >
                TransferIQ
              </Typography>
            </Box>
            {props.isMobile ? (
              // MOBILE SEARCH
              <div
                style={{
                  backgroundColor: "rgba(255, 255, 255, .25)",
                  borderRadius: "50%",
                  border: "1px solid rgba(255, 255, 255, 0.8)",
                  width: "36px",
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  right: "70px",
                }}
              >
                <SearchIcon
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    handleScrollToTop();
                    handleSearchBarClick();
                  }}
                ></SearchIcon>

                <Menu
                  sx={{
                    textAlign: "center",
                    "& .MuiMenu-paper": {
                      maxHeight: "400px",
                      backgroundColor: "#C97026 !important",
                      minWidth: props.isMobile ? "100%" : "auto",
                      width: props.isMobile ? "100%" : "auto",
                      borderRadius: props.isMobile ? "0" : "default",
                      left: props.isMobile ? "0px !important" : "",
                    },
                    "& .MuiList-root": {
                      padding: "0px !important",
                    },
                    marginTop: { xs: "40px", sm: "48px" },
                    zIndex: props.isMobile ? "0" : "1500", // ? how does the zIndex work for the button
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  anchorEl={searchIconEl}
                  open={searchOpen}
                  onClose={handleSearchBarClick}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  TransitionComponent={Slide}
                >
                  <MenuItem disableRipple sx={{}}>
                    <div
                      display="flex"
                      alignItems="center"
                      style={{
                        textAlign: "left",
                        minWidth: "100%",
                        color: props.isMobile ? "white" : "black",
                        fontWeight: props.isMobile ? "600" : "0",
                        paddingTop: props.isMobile ? "5px" : "0",
                        paddingBottom: props.isMobile ? "5px" : "0",
                      }}
                    >
                      <Search
                        //autoFocus
                        sx={{ width: "100%", marginLeft: "0px !important" }}
                      >
                        <StyledInputBase
                          placeholder="Search Player"
                          inputProps={{ "aria-label": "search" }}
                          onChange={(event) => setQuery(event.target.value)}
                          value={query}
                          sx={{
                            width: "90%", // Add this line
                            pointerEvents: "auto",
                          }}
                          onKeyDown={(e) => onKeyDown(e)}
                        //ref={searchInputRef}
                        />
                        <Paper
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            maxHeight: "324px",
                            zIndex: "999",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            boxShadow: "0px 2px 4px rgb(0 0 0 / 20%)",
                            top: "40px",
                          }}
                        >
                          <FixedSizeList
                            height={
                              filteredList.length > 9
                                ? 360
                                : filteredList.length * 36
                            }
                            itemSize={36}
                            itemCount={filteredList.length}
                          >
                            {Row}
                          </FixedSizeList>
                        </Paper>
                        <CloseIcon
                          sx={{
                            border: "solid 2px",
                            borderRadius: "50%",
                            width: "15px",
                            height: "15px",
                            position: "absolute", // Add this line to position the icon
                            right: "10px", // Adjust the right value to position it as needed
                            //top: "50%", // Adjust the top value to center it vertically
                            top: "20px",
                            transform: "translateY(-50%)", // Center it vertically
                            cursor: "pointer",
                            pointerEvents: "auto",
                          }}
                          onClick={() => {
                            handleSearchBarClick();
                            clearSearchBar();
                          }}
                        />
                      </Search>
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <Search sx={{ position: "absolute", right: "70px" }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Player"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(event) => setQuery(event.target.value)}
                  value={query}
                />
                <Paper
                  sx={{
                    width: 320,
                    maxWidth: "100%",
                    maxHeight: "500px",
                    position: "absolute",
                    zIndex: "999",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    boxShadow: "0px 2px 4px rgb(0 0 0 / 20%)",
                  }}
                >
                  <FixedSizeList
                    height={
                      filteredList.length > 9 ? 360 : filteredList.length * 36
                    }
                    width={360}
                    itemSize={36}
                    itemCount={filteredList.length}
                  >
                    {Row}
                  </FixedSizeList>
                </Paper>
              </Search>
            )}
            <AccountCircle
              onClick={handleAccountClick}
              sx={{
                position: "absolute",
                right: "24px",
                cursor: "pointer",
              }}
              style={{
                color: props.isMobile ? "rgba(191, 87, 0, .8)" : "white",
                backgroundColor: props.isMobile ? "white" : "",
                borderRadius: "50%",
                border: props.isMobile
                  ? "1px solid rgba(255, 255, 255, 0.8)"
                  : 0,
              }}
              fontSize="large"
              viewBox="2.5 3 19 19" // ? how does the viewbox work
            ></AccountCircle>
            <Menu
              sx={{
                textAlign: "center",
                "& .MuiMenu-paper": {
                  maxHeight: "400px",
                  backgroundColor: props.isMobile ? "#bf5700" : "",
                  minWidth: props.isMobile ? "100%" : "auto",
                  width: props.isMobile ? "100%" : "auto",
                  borderRadius: props.isMobile ? "0" : "default",
                  left: props.isMobile ? "0px !important" : "",
                },
                "& .MuiList-root": {
                  padding: "0px !important",
                },
                marginTop: props.isMobile
                  ? "6px !important"
                  : "15px !important",
                margin: "0px",
                zIndex: props.isMobile ? "0" : "1300", // ? how does the zIndex work for the button
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id="account button"
              anchorEl={accountMenuAnchorEl}
              open={accountOpen}
              onClose={handleAccountClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                style={{
                  pointerEvents: "none",
                  backgroundColor: "rgba(255, 255, 255, .25)",
                }}
              >
                <div
                  display="flex"
                  alignItems="center"
                  style={{
                    textAlign: "left",
                    color: props.isMobile ? "white" : "black",
                    fontWeight: props.isMobile ? "600" : "0",
                    paddingTop: props.isMobile ? "10px" : "0",
                    paddingBottom: props.isMobile ? "5px" : "0",
                  }}
                >
                  <Typography fontSize="11pt" fontWeight={"600"}>
                    {props.username}
                  </Typography>
                  {props.userId}
                </div>
              </MenuItem>
              <a
                href={
                  process.env.REACT_APP_ENV == "localhost"
                    ? window.location.origin + UserGuide
                    : "https://" +
                    process.env.REACT_APP_ENV +
                    ".net" +
                    UserGuide
                }
                target="_blank"
                rel="noreferrer"
                style={{
                  color: props.isMobile ? "white" : "black",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                disableRipple
              >
                <MenuItem
                  sx={{
                    color: props.isMobile ? "white" : "black",
                    width: "100%",
                  }}
                >
                  <HelpOutline
                    color="primary"
                    sx={{
                      color: props.isMobile ? "white" : "#BF5700",
                      mr: "7px",
                      ml: "-2px",
                    }}
                  />
                  User Guide
                </MenuItem>
              </a>
              {/* <a
                href={props.signOutHandler}
                style={{ textDecoration: "none", color: "black" }}
              > */}
              <MenuItem
                style={{
                  color: props.isMobile ? "white" : "black",
                  fontWeight: props.isMobile ? "600" : "0",
                }}
                onClick={props.signOutHandler}
              >
                <Logout
                  sx={{
                    marginRight: "7px",
                    color: props.isMobile ? "white" : "#BF5700",
                  }}
                ></Logout>
                Logout
              </MenuItem>
              {/* </a> */}
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
