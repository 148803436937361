import { Card, Typography, Box } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import BlockHeader from "./BlockHeader";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "2rem",
  },
}));

function seasonFormat(year) {
  const newYear = year.substring(0, 4) + "-" + year.substring(7);
  return newYear;
}

function KeyStats(props) {
  return (
    <Box sx={{ width: "100%" }}>
      <BlockHeader
        text={seasonFormat(props.season) + " Season Stats"}
      ></BlockHeader>
      <Card sx={{ padding: props.isMobile ? "0px" : "" }}>
        <div className="KeyStatsTable">
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTypography-body2": {
                    fontSize: props.isMobile ? ".8rem" : ".875rem",
                  },
                }}
              >
                <TableCell align="center">
                  <Typography color="gray" variant="body2">
                    PTS
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography color="gray" variant="body2">
                    REB
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography color="gray" variant="body2">
                    AST
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography color="gray" variant="body2">
                    FG%
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {isNaN(props.ppg) ? (
                  <StyledTableCell>
                    <Skeleton
                      width="50%"
                      sx={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      <StyledTableCell>.</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">{props.ppg}</StyledTableCell>
                )}
                {isNaN(props.rpg) ? (
                  <StyledTableCell>
                    <Skeleton
                      width="50%"
                      sx={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      <StyledTableCell>.</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">{props.rpg}</StyledTableCell>
                )}
                {isNaN(props.apg) ? (
                  <StyledTableCell>
                    <Skeleton
                      width="50%"
                      sx={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      <StyledTableCell>.</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">{props.apg}</StyledTableCell>
                )}
                {isNaN(props.fgpct) ? (
                  <StyledTableCell>
                    <Skeleton
                      width="50%"
                      sx={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      <StyledTableCell>.</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">
                    {props.fgpct}
                  </StyledTableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Card>
    </Box>
  );
}

export default KeyStats;
