import * as React from 'react';
import { Box, TextField } from '@mui/material';
import { MemoizedFilterChecklist } from './FilterChecklist';
import { Block } from '@mui/icons-material';

export default function FilterMenuSchool(props) {
  return (
    <Box id="school-menu"
      sx={{
        position: 'fixed',
        top: props.position ? props.position.getBoundingClientRect().top : 0,
        left: props.position ? props.position.getBoundingClientRect().right : 0,
        display: props.display,
        maxHeight: '400px',
        zIndex: '2000',
        background: 'white',
        overflowY: 'scroll',
        width: 'max-content',
        borderRadius: '4px',
        boxShadow: '1px 1px 10px rgb(0 0 0 / 35%)'
      }}
      onMouseEnter={props.handleMouseEnter}
      onMouseLeave={props.handleMouseLeave}
    >
      <TextField
        id="searchSchool"
        label="Search"
        autoComplete="off"
        value={props.selectedSchool ?? ""}
        onChange={(e) => props.setSelectedSchool(e.target.value)}
        type="text"
        sx={{
          width: "95%",
          marginLeft: '10px',
          marginTop: '10px',
          marginRight: '15px',
        }}
      />
      <MemoizedFilterChecklist filter={props.schoolFilters} listData={props.schoolList} handleCheckItem={props.handleCheckSchool} useImage={true} />
    </Box>
  )
}

