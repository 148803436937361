import * as React from 'react'
import { Box } from '@mui/material'
import { MemoizedFilterChecklist } from './FilterChecklist'

const positions = ["G", "F", "C"]

export default function FilterMenuPosition(props) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: props.position ? props.position.getBoundingClientRect().top : 0,
        left: props.position ? props.position.getBoundingClientRect().right : 0,
        display: props.display,
        zIndex: '2000',
        background: 'white',
        borderRadius: '4px',
        boxShadow: '1px 1px 10px rgb(0 0 0 / 35%)'
      }}
      onMouseEnter={props.handleMouseEnter}
      onMouseLeave={props.handleMouseLeave}
    >
      <MemoizedFilterChecklist filter={props.positionFilters} listData={positions} handleCheckItem={props.handleCheckPosition} />
    </Box>
  )
}