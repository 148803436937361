import * as React from 'react'
import { Box, Button, FormGroup } from '@mui/material'
import RoleFilter from './RoleFilter'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

/* Display the menu of role filters */
export default function FilterMenuRole(props) {

    const roles = ['scorer', 'shooter', 'finisher', 'playmaker', 'rebounder', 'defender', 'rimprotector']
    const rolesFormatted = ['Scorer', 'Shooter', 'Finisher', 'Playmaker', 'Rebounder', 'Defender', 'Rim Protector']

    const [keyCounter, setKeyCounter] = React.useState(0)

    const addFilter = () => {
        props.updateRoleFilters(keyCounter, 0, { 'key': keyCounter, 'role': '', 'operator': 'greater_than', 'threshold': '80' })
        setKeyCounter(keyCounter + 1)
    }

    // remove any current filters from the list of available roles
    let availableRoles = [...roles]
    let availableRolesFormatted = [...rolesFormatted]
    props.roleFilters.map((filter, index) => {
        for (let i = 0; i < availableRoles.length; i++) {
            if (filter.role === availableRoles[i]) {
                availableRoles.splice(i, 1)
                availableRolesFormatted.splice(i, 1)
                break
            }
        }
    })

    return (
        <Box id='specialty-menu'
            sx={{
                position: 'fixed',
                top: props.position ? props.position.getBoundingClientRect().top : 0,
                left: props.position ? props.position.getBoundingClientRect().right : 0,
                display: props.display,
                maxHeight: '400px',
                zIndex: '2000',
                background: 'white',
                overflowY: 'scroll',
                width: 'fit-content',
                borderRadius: '4px',
                boxShadow: '1px 1px 10px rgb(0 0 0 / 35%)'
            }}
            onMouseEnter={props.handleMouseEnter}
            onMouseLeave={props.handleMouseLeave}
        >
            <FormGroup sx={{ 'pointerEvents': 'auto' }}>
                {props.roleFilters.map((filter, index) => {
                    const roleIndex = roles.indexOf(filter.role)
                    let roleFormatted = ''
                    if (roleIndex >= 0) {
                        roleFormatted = rolesFormatted[roleIndex]
                    }
                    return <RoleFilter selectedFilterName={roleFormatted} availableRoles={availableRoles} availableRolesFormatted={availableRolesFormatted} key={filter.key} filter={filter} updateRoleFilters={props.updateRoleFilters}></RoleFilter>
                })}
                {props.roleFilters.length < 7 ?
                    <Button onClick={addFilter}>
                        <AddCircleOutlineIcon sx={{ 'color': '#2FC68A', 'marginRight': '5px' }} />
                        Add Role Filter
                    </Button> : ''
                }
            </FormGroup>
        </Box>
    )
}

