import * as React from 'react'
import { Box, Card, FormControl, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { LocalizationProvider, DatePicker } from '@mui/lab'
import ShotChart from './ShotChart'
import BlockHeader from './BlockHeader'
import axios from "axios"
import ShotChartLegend from './ShotChartLegend'

export default function ShotChartControls(props) {

  const [formOptions, setformOptions] = React.useState({
    showMakes: true,
    showMisses: true,
    timeframe: 'Season',
    season: props.season,
    selectedDate: new Date()
  })

  const [shotData, setShotData] = React.useState([])
  const [shotAvgs, setShotAvgs] = React.useState([])

  const fetchSeasonShotData = async () => {
    const data = await axios.get(`${process.env.REACT_APP_FLASK_URL}/seasonshots?league=${props.league}&playerid=${props.playerId}&season=${props.season}`)
    setShotData(data.data.shots)
  }

  const fetchSingleGameData = async () => {
    let isoDate = formOptions.selectedDate.toISOString()
    let date = isoDate.split('T')[0]
    const data = await axios.get(`${process.env.REACT_APP_FLASK_URL}/gameshots?league=${props.league}&playerid=${props.playerId}&date=${date}`)
    setShotData(data.data.shots)
  }

  const fetchShotAverages = async () => {
    /*
    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/shotaverages?league=` + props.league + '?season=' + props.season
    );
    */
    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/shotaverages?league=` + props.league
    );
    let i = 0;
    while (i < data.data.shot_averages.length && data.data.shot_averages[i].season != props.season) {
      i++;
    }
    setShotAvgs(data.data.shot_averages[i]);
  };

  React.useEffect( () => {
    fetchShotAverages();
  },
   [props.league, props.season]);

  React.useEffect(() => {
    fetchSeasonShotData()
    setformOptions((prevState) => {
      return {
        ...prevState,
        timeframe: 'Season'
      }
    })
  }, [props.playerId, props.season])

  /*React.useEffect(() => {
    fetchSingleGameData()
  }, [formOptions.selectedDate])
*/
  const handleTimeframeChange = (event) => {
    const { name, value } = event.target
    if (value == 'Season') {
      fetchSeasonShotData()
    }
    else {
      fetchSingleGameData()
    }
    setformOptions((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target
    setformOptions((prevState) => {
      return {
        ...prevState,
        [name]: checked
      }
    })
  }

  const handleDateChange = (date) => {
    setformOptions((prevState) => {
      return {
        ...prevState,
        selectedDate: date
      }
    })
  }
 
  return (
    <Box sx={{ width: '100%', marginTop: '24px' }}>
      <BlockHeader text='Shot Chart'></BlockHeader>
      <Card sx={{ padding: '20px' }}>
        <ShotChart isMobile={props.isMobile} data={shotData} timeframe={formOptions.timeframe} showMakes={formOptions.showMakes} showMisses={formOptions.showMisses} shotAverages = {shotAvgs} />
        {formOptions.timeframe === 'Single Game' ? '' : <ShotChartLegend isMobile={props.isMobile} />}
        {/*{formOptions.timeframe === 'Single Game' ?
          <Box>
            <FormControl sx={{ mt: 1, ml: 1, minWidth: 120, flexDirection: 'row' }}>
              <FormControlLabel control={
                <Checkbox name='showMakes'
                  checked={formOptions.showMakes}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: '#0fbb68',
                    '&.Mui-checked': { color: '#0fbb68' }
                  }}
                />} label='Makes' />
              <FormControlLabel control={
                <Checkbox name='showMisses'
                  checked={formOptions.showMisses}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: '#ababab',
                    '&.Mui-checked': { color: '#ababab' }
                  }}
                />} label='Misses' />
            </FormControl>
          </Box> :
          ''
        }
        <Box mt={1}>
          <FormControl sx={{ mt: 2, ml: 1, minWidth: 120 }}>
            <InputLabel id='timeframe-label'>Timeframe</InputLabel>
            <Select
              labelId='timeframe-label'
              id='timeframe-select'
              name='timeframe'
              value={formOptions.timeframe}
              label='Timeframe'
              onChange={handleTimeframeChange}
              size='small'
            >
              <MenuItem value={'Season'}>Season</MenuItem>
              <MenuItem value={'Single Game'}>Single Game</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ mt: 2, ml: 2, maxWidth: 150, display: formOptions.timeframe === 'Single Game' ? 'inline-flex' : 'none' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name='selectedDate'
                label='Game Date'
                value={formOptions.selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField size='small' {...params} />}
              />
            </LocalizationProvider>
      </FormControl>
      </Box>*/}
      </Card>
    </Box>
  )
}
